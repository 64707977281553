'use strict';

import Dispatcher from './../Dispatcher';
import Constants  from './../Constants';

const ActionTypes = Constants.ActionTypes;

const ActionCreator = {

	Browser: {
		preloadMedia: function(url, useAuthentication = true) {
			Dispatcher.dispatch({
				type: ActionTypes.Browser.PRELOAD_MEDIA,
				url:  url,
				useAuthentication,
			});
		},

		setShowStatusBar: function(showStatusBar) {
			Dispatcher.dispatch({
				type:          ActionTypes.Browser.SET_SHOW_STATUS_BAR,
				showStatusBar: showStatusBar,
			});
		},

		closeModalWindow: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Browser.CLOSE_MODAL,
			});
		},

	},

	Breadcrumb: {
		loadBreadcrumb: function(breadcrumbType, data, breadcrumbKey) {
			Dispatcher.dispatch({
				type:           ActionTypes.Breadcrumb.LOAD_BREADCRUMB,
				breadcrumbType: breadcrumbType,
				data:           data,
				breadcrumbKey:  breadcrumbKey,
			});
		},
	},

	Conversion: {
		closeIncentive: function(incentive, data) {
			Dispatcher.dispatch({
				type: ActionTypes.Conversion.CLOSE_INCENTIVE,
				incentive,
				data,
			});
		},

		loadBuyImageAlbumIncentives: function(albumId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Conversion.LOAD_BUY_IMAGE_ALBUM_INCENTIVES,
				albumId: albumId,
			});
		},

		loadIncentivesByEvent: function(event) {
			Dispatcher.dispatch({
				type:  ActionTypes.Conversion.LOAD_INCENTIVES_BY_TYPE,
				event: event,
			});
		},

		loadPageLoadIncentives: function(routeName, routeArgs) {
			Dispatcher.dispatch({
				type:      ActionTypes.Conversion.LOAD_PAGE_LOAD_INCENTIVES,
				routeArgs: routeArgs,
				routeName: routeName,
			});
		},

		loadVideoPreviewEndIncentives: function(albumId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Conversion.LOAD_VIDEO_PREVIEW_END_INCENTIVES,
				albumId: albumId,
			});
		},

		setGuestAlreadySignedUp: function(alreadySignedUp) {
			Dispatcher.dispatch({
				type:            ActionTypes.Conversion.SET_GUEST_ALREADY_SIGNED_UP,
				alreadySignedUp: alreadySignedUp,
			});
		},

		setGuestInfo: function(infoType, data) {
			Dispatcher.dispatch({
				type:     ActionTypes.Conversion.SET_GUEST_INFO,
				data:     data,
				infoType: infoType,
			});
		},

		setConversionWindowHidden: function(incentiveType, interval, incentiveRegion) {
			Dispatcher.dispatch({
				type:            ActionTypes.Conversion.SET_CONVERSION_REFILL_WINDOW_HIDDEN,
				incentiveType:   incentiveType,
				interval:        interval,
				incentiveRegion: incentiveRegion,
			});
		},
	},

	Gallery: {
		buyAlbum:          function(albumId, trackerContext) {
			Dispatcher.dispatch({
				type:    ActionTypes.Gallery.BUY_ALBUM,
				albumId: albumId,
				trackerContext,
			});
		},
		updateGalleryData: function(albumId, useCache) {
			Dispatcher.dispatch({
				type:     ActionTypes.Gallery.UPDATE_GALLERY_DATA,
				albumId:  albumId,
				useCache: useCache,
			});
		},

		addComment: function(albumId, commentText) {
			Dispatcher.dispatch({
				type:        ActionTypes.Gallery.ADD_COMMENT,
				albumId:     albumId,
				commentText: commentText,
			});
		},

		showMoreComments: function(albumId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Gallery.SHOW_MORE_COMMENTS,
				albumId: albumId,
			});
		},

		setGuestRating: function(albumId, rating) {
			Dispatcher.dispatch({
				type:    ActionTypes.Gallery.SET_GUEST_RATING,
				albumId: albumId,
				rating:  rating,
			});
		},

		setPictureGuestRating: function(pictureId, rating) {
			Dispatcher.dispatch({
				type:      ActionTypes.Gallery.SET_PICTURE_GUEST_RATING,
				pictureId: pictureId,
				rating:    rating,
			});
		},

		loadSedcards: function(actorId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Gallery.LOAD_SEDCARDS,
				actorId: actorId,
			});
		},

		setSedcardsGuestRating: function(actorId, rating) {
			Dispatcher.dispatch({
				type:    ActionTypes.Gallery.GALLERY_SET_SEDCARDS_GUEST_RATING,
				actorId: actorId,
				rating:  rating,
			});
		},
		loadPreview:            function(actorName, albumId, isMobile) {
			Dispatcher.dispatch({
				type:      ActionTypes.Gallery.LOAD_PREVIEW,
				actorName: actorName,
				albumId:   albumId,
				isMobile:  isMobile,
			});
		},
	},

	Guest: {
		requestGuestBalance: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.REQUEST_GUEST_BALANCE,
			});
		},

		setShowDataProtection: function(showDataProtection) {
			Dispatcher.dispatch({
				type:               ActionTypes.Guest.SET_SHOW_DATA_PROTECTION,
				showDataProtection: showDataProtection,
			});
		},

		setAutoRechargeTeaserState: function(mode) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.SET_AUTO_RECHARGE_TEASER_STATE,
				mode: mode,
			});
		},

		setVideoLike: function(id) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.SET_VIDEO_LIKE,
				id,
			});
		},

		setVideoDislike: function(id) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.SET_VIDEO_DISLIKE,
				id,
			});
		},

		setGalleryLike: function(id) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.SET_GALLERY_LIKE,
				id,
			});
		},

		setGalleryDislike: function(id) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.SET_GALLERY_DISLIKE,
				id,
			});
		},

		setBalanceFormatted: function(balance, balanceFormatted, isPayingCustomer) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.SET_BALANCE_FORMATTED,
				balance,
				balanceFormatted,
				isPayingCustomer,
			});
		},

		setVouchers: function(vouchers) {
			Dispatcher.dispatch({
				type:     ActionTypes.Guest.SET_VOUCHERS,
				vouchers: vouchers,
			});
		},

		acceptCancellationOffer: function(guestId, platformText, offerActivationFail, offerActivationSuccess) {
			Dispatcher.dispatch({
				type:                   ActionTypes.Guest.ACCEPT_CANCELLATION_OFFER,
				guestId:                guestId,
				platformText:           platformText,
				offerActivationFail:    offerActivationFail,
				offerActivationSuccess: offerActivationSuccess,
			});
		},

		closeDataProtection: function(accepted) {
			Dispatcher.dispatch({
				type:     ActionTypes.Guest.CLOSE_DATA_PROTECTION,
				accepted: accepted,
			});
		},

		closeAgbAndDataProtection: function(accepted) {
			Dispatcher.dispatch({
				type:     ActionTypes.Guest.CLOSE_AGB_AND_DATA_PROTECTION,
				accepted: accepted,
			});
		},

		getNotificationsSettings: function(onSuccess, onFail) {
			Dispatcher.dispatch({
				type:      ActionTypes.Guest.GET_NOTIFICATIONS_SETTINGS,
				onSuccess: onSuccess,
				onFail:    onFail,
			});
		},

		sendVideoShareEmail: function(recipient, message, videoId, guestId) {
			Dispatcher.dispatch({
				type:      ActionTypes.Guest.SEND_VIDEO_SHARE_EMAIL,
				recipient: recipient,
				message:   message,
				videoId:   videoId,
				guestId:   guestId,
			});
		},

		setHasAvs: function(hasAvs) {
			Dispatcher.dispatch({
				type:   ActionTypes.Guest.SET_HAS_AVS,
				hasAvs: hasAvs,
			});
		},

		setAvsStatus: function(avsStatus) {
			Dispatcher.dispatch({
				type:      ActionTypes.Guest.SET_AVS_STATUS,
				avsStatus: avsStatus,
			});
		},

		setAvsRejectMessage: function(rejectMessage) {
			Dispatcher.dispatch({
				type:          ActionTypes.Guest.SET_AVS_REJECT_MESSAGE,
				rejectMessage: rejectMessage,
			});
		},

		setHasWelcomeBonusRedeemed: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.SET_HAS_WELCOME_BONUS_REDEEMED,
			});
		},

		setIsLocked: function(isLocked) {
			Dispatcher.dispatch({
				type:     ActionTypes.Guest.SET_IS_LOCKED,
				isLocked: isLocked,
			});
		},

		setCanOneClick: function(oneClickConfig, oneClickAmounts, autoRechargeConfig, autoRechargeAmount, isPaying) {
			Dispatcher.dispatch({
				type:               ActionTypes.Guest.SET_CAN_ONECLICK,
				oneClickConfig:     oneClickConfig,
				oneClickAmounts:    oneClickAmounts,
				autoRechargeConfig: autoRechargeConfig,
				autoRechargeAmount: autoRechargeAmount,
				isPaying:           isPaying,
			});
		},

		setIsVip(isVip) {
			Dispatcher.dispatch({
				type:  ActionTypes.Guest.SET_IS_VIP,
				isVip: isVip,
			});
		},

		setIsVoicecallActive(isVoicecallActive) {
			Dispatcher.dispatch({
				type:              ActionTypes.Guest.SET_IS_VOICECALL_ACTIVE,
				isVoicecallActive: isVoicecallActive,
			});
		},

		setIsVXTVAboSubscriber(isVXTVAboSubscriber) {
			Dispatcher.dispatch({
				type:                ActionTypes.Guest.SET_IS_VXTV_ABO_SUBSCRIBER,
				isVXTVAboSubscriber: isVXTVAboSubscriber,
			});
		},

		setWasVXTVAboSubscriber(wasVXTVAboSubscriber) {
			Dispatcher.dispatch({
				type:                 ActionTypes.Guest.SET_WAS_VXTV_ABO_SUBSCRIBER,
				wasVXTVAboSubscriber: wasVXTVAboSubscriber,
			});
		},

		subscribeNewsletter(typeId, userId = null, emailAddress = null) {
			Dispatcher.dispatch({
				type:         ActionTypes.Guest.NEWSLETTER_SUBSCRIBE,
				typeId:       typeId,
				userId:       userId,
				emailAddress: emailAddress,
			});
		},

		unsubscribeNewsletter(userId, emailAddress, option, reasons) {
			Dispatcher.dispatch({
				type:         ActionTypes.Guest.NEWSLETTER_UNSUBSCRIBE,
				emailAddress: emailAddress,
				option:       option,
				reasons:      reasons,
				userId:       userId,
			});
		},

		redeemBonus(bonusCode) {
			Dispatcher.dispatch({
				type:      ActionTypes.Guest.REDEEM_BONUS,
				bonusCode: bonusCode,
			});
		},

		removeRedeemableGifts(giftIds) {
			Dispatcher.dispatch({
				type:    ActionTypes.Guest.REMOVE_REDEEMABLE_GIFTS,
				giftIds: giftIds,
			});
		},

		sendFeatureFeedback(featureId, rating, text, url) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.SEND_FEATURE_FEEDBACK,
				featureId,
				rating,
				text,
				url,
			});
		},

		startPincallVerification(phoneNumber, verificationType, language) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.START_PINCALL_VERIFICATION,
				phoneNumber,
				verificationType,
				language,
			});
		},

		perpareAvsPayRequest() {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.PERPARE_AVS_PAY_REQUEST,
			});
		},

		isAvsBonusAvailable() {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.AVS_BONUS_AVAILABLE,
			});
		},

		verifyPincall(id, verificationType, pin) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.VERIFY_PINCALL,
				id,
				verificationType,
				pin,
			});
		},
		setBenefitsCode: function(code) {
			Dispatcher.dispatch({
				type: ActionTypes.Guest.BENEFITS_CODE,
				code,
			});
		},

	},

	Promo: {
		hasGuestNotRedeemedOnTopCodeInstanceByName: function(promoCode) {
			Dispatcher.dispatch({
				type:      ActionTypes.Promo.HAS_GUEST_NOT_REDEEMED_PROMO_CODE_INSTANCE,
				promoCode: promoCode,
			});
		},
	},

	Messenger: {

		selectChannelById: function(channelId, touchChannel, isRestored) {
			Dispatcher.dispatch({
				type:         ActionTypes.Messenger.SELECT_CHANNEL,
				channelId:    channelId,
				touchChannel: touchChannel,
				isRestored:   isRestored,
			});
		},

		selectChannelByActorId: function(actorId, touchChannel, isRestored, shouldSelect) {
			Dispatcher.dispatch({
				type:         ActionTypes.Messenger.SELECT_CHANNEL,
				actorId:      actorId,
				touchChannel: touchChannel,
				isRestored:   isRestored,
				shouldSelect: shouldSelect,
			});
		},

		unselectChannel: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Messenger.UNSELECT_CHANNEL,
			});
		},

		filterOnlineChannels: function(active) {
			Dispatcher.dispatch({
				type:   ActionTypes.Messenger.FILTER_ONLINE_CHANNELS,
				active: active,
			});
		},

		addGiftToMessage: function(giftId) {
			Dispatcher.dispatch({
				type:   ActionTypes.Messenger.ADD_GIFT_TO_MESSAGE,
				giftId: giftId,
			});
		},

		updateRecommendedActors: function(currentActorId, fsk) {
			Dispatcher.dispatch({
				type:           ActionTypes.Messenger.UPDATE_RECOMMENDED_ACTORS,
				currentActorId: currentActorId,
				fsk:            fsk,
			});
		},

		updateRecommendedActorsGrid: function(currentActorId) {
			Dispatcher.dispatch({
				type:           ActionTypes.Messenger.UPDATE_RECOMMENDED_ACTORS_GRID,
				currentActorId: currentActorId,
			});
		},

		setAllMessagesRead: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Messenger.SET_ALL_MESSAGES_READ,
			});
		},

		setRestorePreviousUrl: function(restorePreviousUrl) {
			Dispatcher.dispatch({
				type:               ActionTypes.Messenger.SET_RESTORE_PREVIOUS_URL,
				restorePreviousUrl: restorePreviousUrl,
			});
		},

		removeGiftFromMsg: function(giftId) {
			Dispatcher.dispatch({
				type: ActionTypes.Messenger.REMOVE_GIFT_FROM_MESSAGE,
				giftId,
			});
		},

		setUnreadMessagesCount: function(unreadCount) {
			Dispatcher.dispatch({
				type: ActionTypes.Messenger.SET_UNREAD_MESSAGES_COUNT,
				unreadCount,
			});
		},

		setIsChatExitOpen: function(isOpen) {
			Dispatcher.dispatch({
				type:   ActionTypes.Messenger.SET_IS_CHAT_EXIT_OPEN,
				isOpen: isOpen,
			});
		},

		buyChatTicket: function(ticket, creationType = Constants.TicketCreationType.CHAT_TICKET) {
			Dispatcher.dispatch({
				type:         ActionTypes.Messenger.BUY_CHAT_TICKET,
				ticket:       ticket,
				creationType: creationType
			});
		},

        removeChatTicket: function(ticket) {
			Dispatcher.dispatch({
				type:         ActionTypes.Messenger.REMOVE_CHAT_TICKET,
				ticket:       ticket,
			});
		},

	},

	Notification: {

		sendMessage: function(channelId, senderType, messageType, isVideochat, isFreechat, data, includedGifts, chatId) {
			Dispatcher.dispatch({
				type:          ActionTypes.Notification.SEND_MESSAGE,
				channelId:     channelId,
				senderType:    senderType,
				messageType:   messageType,
				isVideochat:   isVideochat,
				isFreechat:    isFreechat,
				data:          data,
				includedGifts: includedGifts,
				chatId:        chatId,
			});
		},

		setAllChannelsSeen: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Notification.SET_ALL_CHANNELS_SEEN,
			});
		},

		loadMoreChannels: function(limit, offset) {
			Dispatcher.dispatch({
				type:   ActionTypes.Notification.LOAD_MORE_CHANNELS,
				offset: offset,
				limit:  limit,
			});
		},

		loadOnlineChannels: function(online) {
			Dispatcher.dispatch({
				type:   ActionTypes.Notification.LOAD_ONLINE_CHANNELS,
				online: online,
			});
		},

		filterChannels: function(filterType) {
			Dispatcher.dispatch({
				type:       ActionTypes.Notification.FILTER_CHANNELS,
				filterType: filterType,
			});
		},

		canOneClick: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Notification.CAN_ONECLICK,
			});
		},

		chargeOneClick: function(amount, channelId) {
			Dispatcher.dispatch({
				type:      ActionTypes.Notification.CHARGE_ONECLICK,
				amount:    amount,
				channelId: channelId,
			});
		},

		markNavbarNotificationAsRead: function(notificationId) {
			Dispatcher.dispatch({
				type:           ActionTypes.Notification.MARK_NAVBAR_NOTIFICATION_AS_READ,
				notificationId: notificationId,
			});
		},

		markNavbarNotificationAsSeen: function(notificationId) {
			Dispatcher.dispatch({
				type:           ActionTypes.Notification.MARK_NAVBAR_NOTIFICATION_AS_SEEN,
				notificationId: notificationId,
			});
		},

		markFloatingNotificationAsRead: function(notificationId) {
			Dispatcher.dispatch({
				type:           ActionTypes.Notification.MARK_FLOATING_NOTIFICATION_AS_READ,
				notificationId: notificationId,
			});
		},

		markFloatingNotificationAsSeen: function(notificationId) {
			Dispatcher.dispatch({
				type:           ActionTypes.Notification.MARK_FLOATING_NOTIFICATION_AS_SEEN,
				notificationId: notificationId,
			});
		},

		refreshEmailProperties: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Notification.REFRESH_EMAIL_PROPERTIES,
			});
		},

		passwordChanged: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Notification.PASSWORD_CHANGED,
			});
		},

		sendSignupEmail: function(email) {
			Dispatcher.dispatch({
				type:  ActionTypes.Notification.SEND_SIGNUP_EMAIL,
				email: email,
			});
		},

		pinActor: function(actorId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Notification.FAVORITE_PIN_ACTOR,
				actorId: actorId,
			});
		},

		unpinActor: function(actorId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Notification.FAVORITE_UNPIN_ACTOR,
				actorId: actorId,
			});
		},

		setPinnedActorEmailNotification: function(actorId, enabled) {
			Dispatcher.dispatch({
				type:    ActionTypes.Notification.FAVORITE_SET_PINNED_ACTOR_EMAIL_NOTIFICATION,
				actorId: actorId,
				enabled: enabled,
			});
		},

		setPinnedActorSMSNotification: function(actorId, enabled) {
			Dispatcher.dispatch({
				type:    ActionTypes.Notification.FAVORITE_SET_PINNED_ACTOR_SMS_NOTIFICATION,
				actorId: actorId,
				enabled: enabled,
			});
		},

		pinSedcards: function(actorId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Notification.FAVORITE_PIN_SEDCARDS,
				actorId: actorId,
			});
		},

		unpinSedcards: function(actorId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Notification.FAVORITE_UNPIN_SEDCARDS,
				actorId: actorId,
			});
		},

		pinAlbum: function(albumId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Notification.FAVORITE_PIN_ALBUM,
				albumId: albumId,
			});
		},

		unpinAlbum: function(albumId) {
			Dispatcher.dispatch({
				type:    ActionTypes.Notification.FAVORITE_UNPIN_ALBUM,
				albumId: albumId,
			});
		},

		pinPicture: function(pictureId) {
			Dispatcher.dispatch({
				type:      ActionTypes.Notification.FAVORITE_PIN_PICTURE,
				pictureId: pictureId,
			});
		},

		unpinPicture: function(pictureId) {
			Dispatcher.dispatch({
				type:      ActionTypes.Notification.FAVORITE_UNPIN_PICTURE,
				pictureId: pictureId,
			});
		},

		archiveChannel: function(channelId, status) {
			Dispatcher.dispatch({
				type:      ActionTypes.Notification.ARCHIVE_CHANNEL,
				channelId: channelId,
				status:    status,
			});
		},

		removeStatusBar: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Notification.REMOVE_STATUS_BAR,
			});
		},
	},

	VideoV2: {
		loadVideos: function(filter, order, count, offset, id) {
			Dispatcher.dispatch({
				type: ActionTypes.VideoV2.LOAD_VIDEOS,
				filter,
				order,
				count,
				offset,
				id,
			});
		},
	},

	Galleries: {
		loadGalleries: function(filter, sort, count, offset, id, ignoreLoading) {
			Dispatcher.dispatch({
				type: ActionTypes.Galleries.LOAD_GALLERY,
				filter,
				sort,
				count,
				offset,
				id,
				ignoreLoading,
			});
		},
	},

	Actors: {
		loadActors: function(first, last, offset, order, filter, preset, id, isV3, onlineFilter) {
			Dispatcher.dispatch({
				type: ActionTypes.Actors.LOAD_ACTORS,
				first, last, offset, order, filter, preset, id, isV3, onlineFilter
			});
		},
	},

	TVSeries: {
		loadTVSeries: function(count, offset) {
			Dispatcher.dispatch({
				type: ActionTypes.TVSeries.LOAD_SERIES,
				count,
				offset,
			});
		},
	},

	SearchResult: {
		clearResultFull: function(searchPattern) {
			Dispatcher.dispatch({
				type:          ActionTypes.SearchResult.CLEAR_RESULT_FULL,
				searchPattern: searchPattern,
			});
		},

		setActorOnlineState: function(actorId, state) {
			Dispatcher.dispatch({
				type:    ActionTypes.SearchResult.SET_ACTOR_ONLINE_STATE,
				actorId: actorId,
				state:   state,
			});
		},

		setSearchPatternFull: function(searchPattern) {
			Dispatcher.dispatch({
				type:          ActionTypes.SearchResult.SET_PATTERN_FULL,
				searchPattern: searchPattern,
			});
		},

		setSearchPatternByType: function(searchPattern, searchType, sort, order) {
			Dispatcher.dispatch({
				type:          searchType,
				searchPattern: searchPattern,
				searchType:    searchType,
				sort:          sort,
				order:         order,
			});
		},

		setSearchPatternQuick: function(searchPattern) {
			Dispatcher.dispatch({
				type:          ActionTypes.SearchResult.SET_PATTERN_QUICK,
				searchPattern: searchPattern,
			});
		},
	},

	Special: {
		loadSpecialEventData: function(eventType, qmDate) {
			Dispatcher.dispatch({
				type:      ActionTypes.Special.LOAD_SPECIAL_EVENT_DATA,
				eventType: eventType,
				qmDate:    qmDate,
			});
		},

		loadSpecials: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Special.LOAD_SPECIAL_EVENTS,
			});
		},

		loadSpitOrSwallowConfig: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Special.LOAD_SPIT_OR_SWALLOW_CONFIG,
			});
		},

		setActorOnlineState: function(actorId, state) {
			Dispatcher.dispatch({
				type:    ActionTypes.Special.SET_ACTOR_ONLINE_STATE,
				actorId: actorId,
				state:   state,
			});
		},

		setGamePoints: function(gamePoints) {
			Dispatcher.dispatch({
				type:       ActionTypes.Special.SET_GAME_POINTS,
				gamePoints: gamePoints,
			});
		},

		setLastOnTopInactiveByCode: function(code) {
			Dispatcher.dispatch({
				type: ActionTypes.Special.SET_LAST_ON_TOP_INACTIVE_BY_CODE,
				code: code,
			});
		},

		setReward: function(reward) {
			Dispatcher.dispatch({
				type:   ActionTypes.Special.SET_REWARD,
				reward: reward,
			});
		},

		setRewardHistory: function(rewardHistory) {
			Dispatcher.dispatch({
				type:          ActionTypes.Special.SET_REWARD_HISTORY,
				rewardHistory: rewardHistory,
			});
		},

		setTries: function(available, total) {
			Dispatcher.dispatch({
				type:      ActionTypes.Special.SET_TRIES,
				available: available,
				total:     total,
			});
		},

		collectEgg: function(verificationToken, qmDate) {
			Dispatcher.dispatch({
				type:              ActionTypes.Special.COLLECT_EGG,
				verificationToken: verificationToken,
				qmDate
			});
		},

		loadVenusAttacksReward: function(qmDate) {
			Dispatcher.dispatch({
				type:   ActionTypes.Special.LOAD_SPECIAL_VENUS_ATTACKS_REWARD,
				qmDate: qmDate,
			});
		},

		loadWiesnShootingReward: function(qmDate) {
			Dispatcher.dispatch({
				type:   ActionTypes.Special.LOAD_SPECIAL_WIESN_SHOOTING_REWARD,
				qmDate: qmDate,
			});
		},

		loadWetAndWildReward: function(eventId, qmDate) {
			Dispatcher.dispatch({
				type:   ActionTypes.Special.LOAD_SPECIAL_WET_AND_WILD_REWARD,
				eventId,
				qmDate: qmDate,
			});
		},

		loadWiesnBudeReward: function(qmDate) {
			Dispatcher.dispatch({
				type:   ActionTypes.Special.LOAD_SPECIAL_WIESN_BUDE_REWARD,
				qmDate: qmDate,
			});
		},

		loadRewardBoxReward: function(eventId, qmDate) {
			Dispatcher.dispatch({
				type:    ActionTypes.Special.LOAD_SPECIAL_REWARD_BOX_REWARD,
				eventId: eventId,
				qmDate:  qmDate,
			});
		},

		loadWiesnGaudiReward: function(qmDate) {
			Dispatcher.dispatch({
				type:   ActionTypes.Special.LOAD_SPECIAL_WIESN_GAUDI_REWARD,
				qmDate: qmDate,
			});
		},

		loadHalloweenReward: function(qmDate, eventId) {
			Dispatcher.dispatch({
				type: ActionTypes.Special.LOAD_SPECIAL_HALLOWEEN_REWARD,
				qmDate,
				eventId
			});
		},

		showLoader: function(type) {
			Dispatcher.dispatch({
				type:       ActionTypes.Special.SHOW_LOADER,
				loaderType: type,
			});
		},

		hideLoader: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Special.HIDE_LOADER,
			});
		}

	},

	Survey: {
		blockSurvey: function(type) {
			Dispatcher.dispatch({
				type:       ActionTypes.Survey.BLOCK_SURVEY,
				surveyType: type,
			});
		},

		closeSurvey: function(type) {
			Dispatcher.dispatch({
				type:       ActionTypes.Survey.CLOSE_SURVEY,
				surveyType: type,
			});
		},

		loadFeedbackData: function() {
			Dispatcher.dispatch({
				type: ActionTypes.Survey.LOAD_FEEDBACK_DATA,
			});
		},

		openSurvey: function(type) {
			Dispatcher.dispatch({
				type:       ActionTypes.Survey.OPEN_SURVEY,
				surveyType: type,
			});
		},

		storeChatRating: function(chatId, actorId, rating, message, isMobile, isAnonymousVoicecall, chatType) {
			Dispatcher.dispatch({
				type:                 ActionTypes.Survey.STORE_CHAT_RATING,
				chatId:               chatId,
				actorId:              actorId,
				rating:               rating,
				message:              message,
				isMobile:             isMobile,
				isAnonymousVoicecall: isAnonymousVoicecall,
                chatType:             chatType,
			});
		},

		storeFeedbackAnswer: function(isMobile, feedback, email) {
			Dispatcher.dispatch({
				type:     ActionTypes.Survey.STORE_FEEDBACK_ANSWER,
				isMobile: isMobile,
				feedback: feedback,
				email:    email,
			});
		},

	},

	Teaser: {
		loadTeasers: function(routeName, routeArgs) {
			Dispatcher.dispatch({
				type: ActionTypes.Teaser.LOAD_TEASERS,
				routeName,
				routeArgs,
			});
		},
	},

	TileGrid: {
		loadGrid: function(type, payload, useInfiniteScrolling) {
			Dispatcher.dispatch({
				type:                 ActionTypes.TileGrid.LOAD_GRID,
				gridType:             type,
				payload:              payload,
				useInfiniteScrolling: useInfiniteScrolling,
			});
		},

		loadVideoAlbumPictures: function(albumId) {
			Dispatcher.dispatch({
				type:    ActionTypes.TileGrid.LOAD_VIDEO_ALBUM_PICTURES,
				albumId: albumId,
			});
		},

		resetScrollPosition: function(tileGridId) {
			Dispatcher.dispatch({
				type:       ActionTypes.TileGrid.RESET_SCROLL_POSITION,
				tileGridId: tileGridId,
			});
		},

		resetBackLink: function(tileGridId) {
			Dispatcher.dispatch({
				type:       ActionTypes.TileGrid.RESET_BACK_LINK,
				tileGridId: tileGridId,
			});
		},

		setBackLink: function(tileGridId) {
			Dispatcher.dispatch({
				type:       ActionTypes.TileGrid.SET_BACK_LINK,
				tileGridId: tileGridId,
			});
		},

		setRestoreScrollPosition: function(restorePosition) {
			Dispatcher.dispatch({
				type:            ActionTypes.TileGrid.SET_RESTORE_SCROLL_POSITION,
				restorePosition: restorePosition,
			});
		},

		setScrollPosition: function(tileGridId, offset) {
			Dispatcher.dispatch({
				type:       ActionTypes.TileGrid.SET_SCROLL_POSITION,
				offset:     offset,
				tileGridId: tileGridId,
			});
		},

		setTileGridKey: function(tileGridId, tileGridKey) {
			Dispatcher.dispatch({
				type:        ActionTypes.TileGrid.SET_TILE_GRID_KEY,
				tileGridId:  tileGridId,
				tileGridKey: tileGridKey,
			});
		},

		setTileGridMode: function(tileGridId, gridMode) {
			Dispatcher.dispatch({
				type:       ActionTypes.TileGrid.SET_TILE_GRID_MODE,
				tileGridId: tileGridId,
				gridMode:   gridMode,
			});
		},

		/**
		 * @param {String} tileGridId
		 * @param {Boolean} crop
		 */
		toggleGridCrop(tileGridId, crop) {
			Dispatcher.dispatch({
				type: ActionTypes.TileGrid.TOGGLE_CROP,
				tileGridId,
				crop,
			});
		},

		setTileGridFilterList(filterList) {
			Dispatcher.dispatch({
				type: ActionTypes.TileGrid.SET_FILTER_LIST,
				filterList,
			});
		},
	},

	TV: {
		checkPlaylistActors: function() {
			Dispatcher.dispatch({
				type: ActionTypes.TV.CHECK_ACTORS,
			});
		},

		loadFormatData: function(formatKey) {
			Dispatcher.dispatch({
				type:      ActionTypes.TV.LOAD_FORMAT_DATA,
				formatKey: formatKey,
			});
		},

		loadProgramInfo: function(premium, limit) {
			Dispatcher.dispatch({
				type:    ActionTypes.TV.LOAD_PROGRAM_INFO,
				premium: premium,
				limit:   limit,
			});
		},

		loadStreamInfo: function(premium) {
			Dispatcher.dispatch({
				type:    ActionTypes.TV.LOAD_STREAM_INFO,
				premium: premium,
			});
		},

		loadPostData: function(slug) {
			Dispatcher.dispatch({
				type: ActionTypes.TV.LOAD_POST_DATA,
				slug: slug,
			});
		},

		loadVideoData: function(videoId) {
			Dispatcher.dispatch({
				type:    ActionTypes.TV.LOAD_VIDEO_DATA,
				videoId: videoId,
			});
		},

		loadSuggestVideoData: function(videoId) {
			Dispatcher.dispatch({
				type:    ActionTypes.TV.LOAD_SUGGEST_VIDEO_DATA,
				videoId: videoId,
			});
		},

		/**
		 * @param {String} hashId
		 * @param {number} count
		 */
		loadMediathekVideoRecommendations: function(hashId, count) {
			Dispatcher.dispatch({
				type: ActionTypes.TV.LOAD_MEDIATHEK_VIDEO_RECOMMENDATIONS,
				hashId,
				count,
			});
		},

		loadMediathekVideos: function(filter, count, offset) {
			Dispatcher.dispatch({
				type: ActionTypes.TV.LOAD_MEDIATHEK_VIDEOS,
				filter,
				count,
				offset,
			});
		},
	},

	Contest: {
		addContestMediaVoting: function(contestId, mediaId, actorId, guestId, albumId) {
			Dispatcher.dispatch({
				type:      ActionTypes.Contest.ADD_MEDIA_VOTING,
				contestId: contestId,
				mediaId:   mediaId,
				actorId:   actorId,
				guestId:   guestId,
				albumId:   albumId,
			});
		},

		deleteContestMediaVoting: function(contestId, mediaId, actorId, guestId, albumId) {
			Dispatcher.dispatch({
				type:      ActionTypes.Contest.DELETE_MEDIA_VOTING,
				contestId: contestId,
				mediaId:   mediaId,
				actorId:   actorId,
				guestId:   guestId,
				albumId:   albumId,
			});
		},

		loadContestHead: function(contestId, language) {
			Dispatcher.dispatch({
				type:      ActionTypes.Contest.LOAD_CONTEST_HEADER,
				contestId: contestId,
				language:  language,
			});
		},

		loadContestGridItems: function(contestId, contestArea, limit, offset, useCache, actorName) {
			Dispatcher.dispatch({
				type:        ActionTypes.Contest.LOAD_CONTEST_GRID_ITEMS,
				contestArea: contestArea,
				contestId:   contestId,
				limit:       limit,
				offset:      offset,
				useCache:    useCache,
				actorName:   actorName,
			});
		},

		setShouldOpenMedia:             function(shouldOpen, config) {
			Dispatcher.dispatch({
				type:       ActionTypes.Contest.SET_SHOULD_OPEN_MEDIA,
				shouldOpen: shouldOpen,
				config:     config,
			});
		},
		setShouldForwardToProfileVideo: function(shouldForwardToProfileVideo) {
			Dispatcher.dispatch({
				type:                        ActionTypes.Contest.SET_SHOULD_FORWARD_TO_PROFILE_VIDEO,
				shouldForwardToProfileVideo: shouldForwardToProfileVideo,
			});
		},
		setShouldOpenNextContestsModal: function(shouldOpen) {
			Dispatcher.dispatch({
				type:       ActionTypes.Contest.SET_SHOULD_OPEN_NEXT_CONTESTS_MODAL,
				shouldOpen: shouldOpen,
			});
		},
	},

	NavBarSearch: {
		focus: function() {
			Dispatcher.dispatch({
				type: ActionTypes.NavBarSearch.FIELD_FOCUS,
			});
		},
		blur:  function() {
			Dispatcher.dispatch({
				type: ActionTypes.NavBarSearch.FIELD_BLUR,
			});
		},
	},

	Feed: {
		toggleOverlay:        function(postId, overlayType, ImageId) {
			Dispatcher.dispatch({
				type: ActionTypes.Feed.TOOGLE_OVERLAY,
				postId,
				overlayType,
				ImageId
			});
		},
		toggleLike:           function(postId) {
			Dispatcher.dispatch({
				type: ActionTypes.Feed.SET_LIKE,
				postId,
			});
		},
		triggerUpdatePost:    function(postId) {
			Dispatcher.dispatch({
				type: ActionTypes.Feed.TRIGGER_UPDATE_POST,
				postId,
			});
		},
		triggerSetPosts:      function() {
			Dispatcher.dispatch({
				type: ActionTypes.Feed.TRIGGER_SET_POSTS,
			});
		},
		toggleBlockVideoPlay: function(postId) {
			Dispatcher.dispatch({
				type: ActionTypes.Feed.TOGGLE_BLOCK_VIDEO_PLAY,
				postId
			});
		},
		setPlayingVideoId:    function(postId) {
			Dispatcher.dispatch({
				type: ActionTypes.Feed.TOGGLE_VIDEO_PLAY,
				postId,
			});
		},
	},
};

export default ActionCreator;
